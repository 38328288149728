@use "../config/" as *;
@forward "../swiper/swiper-bundle";
.waf-teamlisting {
    @extend %half-radius;
    @extend %m-4;
    @extend %p-4;
    @extend %pure-white-900-bg;
    .head-wrap {
        @extend %flex-column-fs-fs;
        @extend %flex-wrap;
        @extend %gap-3;
    }
    .waf-body {
        @extend %mt-8;
    }
    .site-search-form {
        @extend %w-100;
        @extend %relative;
        .btn-search {
            right: var(--space-4);
            @extend %pos-y-center;
            @extend %font-0;
            &:after {
                @include icon(search, 16);
                @extend %pure-white-900-bg;
            }
        }
    }
    .site-search-input {
        height: 4rem;
        border: .1rem solid var(--neutral-1000);
        @extend %half-radius;
        @extend %w-100;
        @extend %px-4;
        // @extend %pr-8;
    }
    .waf-ad {
        @extend %w-100;
        @extend %mt-4;
    }
    .tab {
        &-section {
            width: 100%;
        }
        &-list {
            list-style-type: none;
            @extend %half-radius;
            @extend %w-100;
            // @extend %mt-1;
            @extend %p-1-2;
            @extend %secondary-1000-bg;
            @extend %gap-1;
            @extend %flex-c-c;
        }
        &-item {
            min-height: 2.7rem;
            width: calc(50% - var(--space-1) / 2);
            @extend %flex-c-c;
            @extend %transparent-bg;
            .btn-tab {
                @extend %w-100;
                @extend %pure-white-900;
                @extend %font-12-pb;
                @extend %uppercase;
            }
            &.tab-item-active {
                @extend %half-radius;
                @extend %primary-300-bg;
                .btn-tab {
                    @extend %neutral-800;
                }
            }
        }
        &-secondary {
            .tab-list {
                @extend %gap-0;
                @extend %p-0;
                @extend %neutral-50-bg;
            }
            .tab-item {
                cursor: pointer;
                .btn-tab {
                    @extend %neutral-600;
                    @extend %py-3;
                }
                &:first-child {
                    &.tab-item-active {
                        border-radius: var(--half-radius) 0 0 var(--half-radius);
                    }
                }
                &:nth-child(2) {
                    &.tab-item-active {
                        border-radius: 0 var(--half-radius) var(--half-radius) 0;
                    }
                }
            }
        }
    }
    .pagination {
        &-result {
            @extend %text-center;
            @extend %w-100;
            @extend %pt-4;
        }
        &-result-text {
            @extend %font-12-pm;
            @extend %neutral-600;
        }
        &-wrappper {
            overflow: auto;
            @extend %flex-wrap;
            @extend %my-8;
            @extend %flex-c-n;
        }
        &-list {
            height: 4.4rem;
            @extend %px-2;
            @extend %half-radius;
            @extend %primary-50-bg;
            @include flex-config(inline-flex, null, center, center);
        }
        &-item {
            @extend %flex-c-c;
            .pagination-text {
                width: max-content;
                min-width: 2.5rem;
                height: 2.5rem;
                padding-inline: .2rem;
                cursor: pointer;
                @extend %neutral-800;
                @extend %font-14-pb;
                @extend %flex-c-c;
                &.active {
                    @extend %primary-300-bg;
                }
            }
        }
    }
    .nav {
        &-first,
        &-last,
        &-left,
        &-right {
            min-width: 2.5rem;
            height: 2.5rem;
            .pagination-text {
                width: unset;
                min-width: unset;
                @extend %font-0;
                @extend %neutral-800;
                &.disabled {
                    pointer-events: none;
                    opacity: 0.5
                }
                &:before {
                    font-size: 1.4rem;
                }
            }
        }
    }
    .nav-first {
        &:before {
            @include icon(p-double-arrow-left);
        }
    }
    .nav-last {
        &:before {
            @include icon(p-double-arrow-right);
        }
    }
    .nav-left {
        &:before {
            @include icon(p-arrow-left);
        }
    }
    .nav-right {
        &:before {
            @include icon(p-arrow-right);
        }
    }
    .team {
        &-list-title {
            @extend %font-14-pb;
            @extend %uppercase;
            @extend %mb-4;
        }
        &-listing {
            @extend %flex-wrap;
            @extend %flex-column-n-n;
            @extend %gap-4;
        }
        &-item {
            @extend %relative;
        }
        &-action {
            .btn-view-more {
                @extend %font-0;
                @extend %pos-bl;
                @extend %w-100;
                @extend %h-100;
            }
        }
        &-thumbnail {
            width: 5rem;
            min-height: 3rem;
            flex-shrink: 0;
            @extend %neutral-50-bg;
            @extend %hidden;
        }
        &-name {
            @extend %gap-1;
            @extend %flex-n-n;
            @extend %font-16-pb;
            @extend %capitalize;
            @extend %flex-wrap;
            .name {
                @extend %neutral-800;
                @extend %font-16-pb;
            }
        }
        &-wrap {
            border: .1rem solid var(--neutral-1000);
            @extend %gap-3;
            @extend %p-5;
            @extend %flex-n-c;
            @extend %relative;
            @extend %half-radius;
            &:not(:first-child) {
                @extend %mt-4;
            }
        }
    }
    .waf-select-box {
        @extend %mx-3;
        @extend %transparent-bg;
        @extend %p-0;
        .selected-title {
            @extend %d-none;
        }
        .select-box-wrap {
            width: calc(100% - 2rem);
            position: unset;
            @extend %transparent-bg;
        }
        .select-list {
            .list-item {
                // min-height: unset;
                @extend %text-center;
                @extend %p-0;
                &:first-child {
                    @extend %mr-3;
                    @extend %relative;
                    &::after {
                        content: '';
                        width: .1rem;
                        @extend %h-70;
                        @extend %neutral-900-bg-5;
                        @include position (null, -1.5rem);
                    }
                }
                &:nth-child(2) {
                    @extend %ml-3;
                }
                &.swiper-slide-active,
                &.active {
                    .list-item-text {
                        font-weight: 700;
                        @extend %neutral-800;
                        @extend %half-radius;
                        @extend %primary-300-bg;
                    }
                }
                button {
                    @extend %text-center;
                }
                .list-item-text {
                    color: clr(neutral-900, 5);
                    @extend %font-12-pm;
                    @extend %p-1-2;
                }
            }
        }
    }
    .filter {
        &-wrap {
            @extend %d-block;
            @extend %w-100;
        }
        &-section {
            position: unset;
            border: .1rem solid var(--neutral-1000);
            @extend %w-100;
        }
    }
    .swiper {
        &-button-next,
        &-button-prev {
            @extend %pos-y-center;
            @extend %transparent-bg;
        }
        &-button-next {
            right: -1.5rem;
            &:after {
                @include icon(p-arrow-right, 12);
            }
        }
        &-button-prev {
            left: -1.5rem;
            &:after {
                @include icon(p-arrow-left, 12);
            }
        }
        &-wrapper {
            @extend %py-1;
        }
    }
    .filter-actions,
    .more-filters,
    .selected-title {
        @extend %d-none;
    }
}
.trending-team {
    @extend %mx-4;
    @extend %half-radius;
    @extend %p-6-5;
    .waf-body {
        overflow-x: unset;
    }
    .trending-list {
        flex-wrap: nowrap;
        width: auto;
        flex-direction: column;
    }
    .trending-action {
        margin-left: auto;
    }
}
@include mq(col-md) {
    .waf-teamlisting {
        margin-inline: 0;
        .swiper {
            width: 100%;
            &-button-prev,
            &-button-next {
                display: none;
            }
            &-wrapper {
                width: unset;
                justify-content: space-between;
                overflow-x: auto;
            }
        }
        .waf-select-box {
            height: auto;
            .select-box-wrap {
                width: 100%;
            }
            .select-list {
                .list-item {
                    width: unset;
                }
            }
        }
        .pagination {
            &-wrappper {
                overflow: unset;
            }
            &-item {
                .pagination-text {
                    width: 3.4rem;
                    height: 3.4rem;
                    margin-inline: var(--space-1);
                    width: max-content;
                    min-width: 3.4rem;
                }
            }
            &-text-dots {
                margin-inline: var(--space-1);
            }
        }
    }
    .trending-team {
        margin-inline: 0;
    }
}
@include mq(col-lg) {
    .waf-teamlisting {
        --_search-width: 25rem;
        --_tab-section-width: 15rem;
        .team {
            &-list-title {
                font-size: 1.6rem;
            }
            &-listing {
                flex-direction: row;
                margin-top: var(--space-4);
            }
            &-item {
                width: calc(50% - var(--space-4) / 2);
                &:nth-child(n) {
                    margin: 0;
                }
            }
        }
        .head-wrap {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .title {
                flex-basis: calc(100% - var(--space-6) - calc(var(--_search-width) + var(--_tab-section-width)));
            }
        }
        .site-search-form {
            width: var(--_search-width);
        }
        .tab-section.tab-secondary {
            width: calc(80% - var(--space-2) / 2);
        }
        .tab-section {
            flex-basis: var(--_tab-section-width);
            &.tab-secondary {
                .tab-item {
                    min-height: 4rem;
                }
            }
        }
        .tab-list {
            margin-top: 0;
        }
        .tab-item {
            min-height: 3.2rem;
        }
    }
    .trending-team {
        padding: var(--space-6) var(--space-5);
    }
}